import { FormProvider, useForm } from "react-hook-form";
import Input from "./Input";
let initialState = {};
const LoanDetails = (props) => {
  const { company, formFields } = props;
  const formRef = useForm(initialState);
  const {
    handleSubmit,
    setValue,
    formState: { isDirty, isValid },
  } = formRef;
  const partnerFields = formFields[company]?.filter(
    (field) => field?.page === "loan-details"
  );
  const formValidationsObj = {};
  partnerFields?.forEach((field) => {
    formValidationsObj[field.name] = { ...field };
    initialState[field.name] = "";
  });

  const redirectToTermsPage = handleSubmit((data) => {
    props?.redirectToStep(data);
  });

  return (
    <>
      <div className="text-2xl text-[#4D4D4D] font-sans font-bold my-7 px-5">
        Enter Loan Details
      </div>
      <FormProvider {...formRef}>
        <form
          onSubmit={(e) => e.preventDefault()}
          noValidate
          autoComplete="off"
          className="my-5 px-5"
        >
          <Input {...formValidationsObj?.loan_amount} />
          <Input {...formValidationsObj?.loan_period} setValue={setValue} />
          <Input {...formValidationsObj?.repayment_frequency} setValue={setValue} />

          <div className="my-7 mx-5 flex items-center justify-center">
          {company === 'moove' && (
              <button
                onClick={() => {
                  localStorage.clear();
                  window.location.reload();
                }}
                className="bg-[#db1d0f] text-white text-base py-2 px-7 rounded disabled:opacity-50 mr-3"
              >
                <span>Reset</span>
              </button>
            )}
            <button
              onClick={redirectToTermsPage}
              disabled={!isDirty || !isValid}
              className="bg-[#4E05FF] text-white text-base py-2 px-3 rounded disabled:opacity-50"
            >
              Continue
            </button>
          </div>
        </form>
      </FormProvider>
    </>
  );
};
export default LoanDetails;
