import React, { useRef, useState, useCallback } from "react";
import Webcam from "react-webcam";

const SelfieCapture = (props) => {
    const webcamRef = useRef(null);
    const [url, setUrl] = useState(null);
    const [facingMode, setFacingMode] = useState("user"); // Default to front camera

    const videoConstraints = {
        width: 540,
        facingMode: facingMode
    };

    const capturePhoto = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        setUrl(imageSrc);
    }, [webcamRef]);

    const toggleCamera = () => {
        setFacingMode(prevMode => prevMode === "user" ? "environment" : "user");
    };

    const handleSubmit = () => {
        props?.redirectToStep(url);
        console.log("Submitting photo:", url);
    };

    return (
        <>
            <div className="text-2xl text-[#4D4D4D] font-sans font-bold my-7 px-5">
                Capture Selfie
            </div>
            <div className="flex flex-col items-center">
                {url ? (
                    <img src={url} alt="Captured" className="w-[540px] h-auto mb-4" />
                ) : (
                    <Webcam
                        ref={webcamRef}
                        audio={false}
                        screenshotFormat="image/jpeg"
                        videoConstraints={videoConstraints}
                        className="mb-4"
                    />
                )}
                <div className="flex gap-4 mb-4">
                    <button
                        onClick={capturePhoto}
                        className="bg-[#4E05FF] text-white text-base py-2 px-3 rounded disabled:opacity-50"
                    >
                        Capture
                    </button>
                    <button
                        onClick={() => setUrl(null)}
                        className="bg-[#4E05FF] text-white text-white text-base py-2 px-3 rounded disabled:opacity-50"
                    >
                        Refresh
                    </button>
                    <button
                        onClick={toggleCamera}
                        className="bg-[#4E05FF] text-white text-base py-2 px-3 rounded disabled:opacity-50"
                    >
                        Switch Camera
                    </button>
                </div>
                <button
                    onClick={handleSubmit}
                    disabled={!url}
                    className="bg-[#4E05FF] text-white text-base py-2 px-3 rounded disabled:opacity-50"
                >
                    Submit
                </button>
            </div>
        </>
    );
};

export default SelfieCapture;
