import { FormProvider, useForm } from "react-hook-form";
import Input from "./Input";
import { useState } from "react";
let initialState = {};
const Terms = (props) => {
  const { company, formFields } = props;
  const formRef = useForm(initialState);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const { handleSubmit } = formRef;
  const partnerFields = formFields[company]?.filter(
    (field) => field?.page === "terms-details"
  );
  const formValidationsObj = {};
  partnerFields?.forEach((field) => {
    formValidationsObj[field.name] = { ...field };
    initialState[field.name] = "";
  });

  const redirectToNextPage = handleSubmit((data) => {
    if (termsAccepted) {
      props?.redirectToStep(data);
    }
  });

  return (
    <>
      <div className="text-2xl text-[#4D4D4D] font-sans font-bold my-7 px-5">
        Read and accept Terms and Conditions
      </div>
      <FormProvider {...formRef}>
        <form
          onSubmit={(e) => e.preventDefault()}
          noValidate
          autoComplete="off"
          className="my-5 px-5"
        >
          <Input {...formValidationsObj?.terms_conditions} customClass="h-12" />
          <div className="flex items-center my-6">
            <input
              checked={termsAccepted}
              id="default-checkbox"
              type="checkbox"
              value=""
              onChange={() => {
                setTermsAccepted((prevState) => !prevState);
              }}
              className="w-4 h-4 text-[#5A786F] bg-gray-100 border-gray-300 rounded focus:ring-[5A786F] dark:focus:ring-[5A786F] dark:ring-offset-gray-800 focus:ring-2"
            />
            <label
              htmlFor="default-checkbox"
              className="ml-2 text-sm font-normal text-[#4D4D4D]"
            >
              <span className="font-bold">I agree</span> to the terms and
              conditions
            </label>
          </div>

          <div className="my-7 mx-5 flex items-center justify-center">
          {company === 'moove' && (
              <button
                onClick={() => {
                  localStorage.clear();
                  window.location.reload();
                }}
                className="bg-[#db1d0f] text-white text-base py-2 px-7 rounded disabled:opacity-50 mr-3"
              >
                <span>Reset</span>
              </button>
            )}
            <button
              onClick={redirectToNextPage}
              disabled={!termsAccepted}
              className="bg-[#4E05FF] text-white text-base py-2 px-3 rounded disabled:opacity-50"
            >
              Continue
            </button>
          </div>
        </form>
      </FormProvider>
    </>
  );
};
export default Terms;
