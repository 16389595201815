import { Fragment, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Input from "./Input";


const PersonalDetails = (props) => {
  const { company, formFields, loanData } = props;
  let fieldsState = {};
  let partnerFields = [];
  if (formFields && company) {
    partnerFields = formFields[company]?.filter(
      (field) => field?.page === "personal-details"
    );
    partnerFields?.forEach((field) => {
      if (!loanData) {
        return
      }
      return (fieldsState[field.name] = loanData[field.name] || "");
    });
  }
  const formRef = useForm(fieldsState);
  const {
    reset,
    handleSubmit,
    trigger,
    // Read the formState before render to subscribe the form state through the Proxy
    formState: { isDirty, isValid },
  } = formRef;

  const redirectToLoanDetailsPage = handleSubmit((data) => {
    props?.redirectToStep(data);
  });

  useEffect(() => {
    setTimeout(() => {
      reset(
        {
          ...fieldsState,
        },
        { keepDefaultValues: true }
      );
      trigger();
    }, 0);
  }, []);

  return (
    <>
      <div className="text-2xl text-[#4D4D4D] font-sans font-bold my-7 px-5">
        Enter personal details
      </div>

      <FormProvider {...formRef}>
        <form
          onSubmit={(e) => e.preventDefault()}
          noValidate
          autoComplete="off"
          className="my-5 px-5"
        >
          <div className="max-h-[500px] overflow-y-auto">
            {partnerFields?.map((element, index) => (
              <Fragment key={`${element.name}-${index}`}>
                {element?.name && element?.name !== "terms_conditions" && (
                  <Input {...element} />
                )}
              </Fragment>
            ))}
          </div>

          <div className="my-7 mx-5 flex items-center justify-center">
          {company === 'moove' && (
              <button
                onClick={() => {
                  localStorage.clear();
                  window.location.reload();
                }}
                className="bg-[#db1d0f] text-white text-base py-2 px-7 rounded disabled:opacity-50 mr-4"
              >
                <span>Reset</span>
              </button>
            )}
            <button
              onClick={redirectToLoanDetailsPage}
              disabled={!isDirty || !isValid}
              className="bg-[#4E05FF] text-white text-base py-2 px-3 rounded disabled:opacity-50"
            >
              Continue
            </button>
          </div>
        </form>
      </FormProvider>
    </>
  );

};
export default PersonalDetails;
