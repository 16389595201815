const SuccessPage = ({ applicationId, company,consentUrl }) => {
  return (
    <>
      <div className="text-xl text-[#4D4D4D] font-sans font-bold my-7 px-5">
        Your application has been successfully registered with us
      </div>
      <div className="my-5 px-5">
        <div className="text-base text-[#4D4D4D] font-sans">
          Your Application Number
        </div>
        <div className="text-base font-sans text-[#404040] font-medium">
          {applicationId || ""}
        </div>
      </div>
      <div className="my-5 px-5">
        <div className="text-base text-[#4D4D4D] font-sans">
          Your Digilocker Consent form! Please fill it to complete the application Process:
        </div>
        <div className="text-base font-sans text-[#404040] font-medium">
          {<a href={consentUrl} style={{color: "#4f06fe" }}> Digilocker form</a> || ""}
        </div>
      </div>
      {company !== "moove" ? (
        <div className="text-xl text-[#4D4D4D] font-sans font-bold my-7 px-5">
          As the next step, you will receive a message on WhatsApp from us to complete the rest of the process
        </div>
      ) : (
        <div className="text-xl text-[#4D4D4D] font-sans font-bold my-7 px-5">
          Please refer to the dashboard provided by us for regular updates
        </div>
      )}
    </>
  );
};

export default SuccessPage;
