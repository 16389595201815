import Header from "../components/Header";
const AdhaarSuccessPage = ({ applicationId, company,consentUrl }) => {
    return (
      <>
       <Header hidePartnerLogo={true} />
        <div className="text-xl text-[#4D4D4D] font-sans font-bold my-7 px-5">
          Your application has been successfully registered with us Thanks for giving your consent to acces your digilocker
        </div>
        <div className="my-5 px-5">
          <div className="text-base text-[#4D4D4D] font-sans">
            Your digilocker Consent form is complete.
          </div>
        </div>
        {/* {company !== "moove" ? (
          <div className="text-xl text-[#4D4D4D] font-sans font-bold my-7 px-5">
            As the next step, you will receive a message on WhatsApp from us to complete the rest of the process
          </div>
        ) : (
          <div className="text-xl text-[#4D4D4D] font-sans font-bold my-7 px-5">
            Please refer to the dashboard provided by us for regular updates
          </div>
        )} */}
      </>
    );
  };
  
  export default AdhaarSuccessPage;
  